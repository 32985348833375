<template>
  <div>
    <div class="white primary--text font-weight-black pt-4 pb-4 pl-8 pr-8 d-flex align-center rounded-lg">
      <div class="info--usuario">USUARIO</div>
      <div class="info--rol">ROL</div>
      <div class="info--action text-center">ACCIÓN</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RolesTableHead",
};
</script>

<style lang="scss" scoped>
.info--usuario {
  width: 30%;
}

.info--rol {
  width: 30%;
}

.info--action {
  width: 40%;
}</style>